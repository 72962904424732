export default function Testimonials({ testimonials: qts = [] }) {
  const randomItem = (array) => array[Math.floor(Math.random() * array.length)];
  const item = qts.length > 0 && qts[Math.floor(Math.random() * qts.length)];

  if (item && item.label) {
    return (
      <ul class="testimonial_list">
        <li class="testimonial">
          <p>
            {item.content}
          </p>
        </li>
        <li class="author">{item.label}</li>
      </ul>
    );
  }

  return (
    <ul class="testimonial_list">
      <li class="testimonial">
        <p>
          Not knowing a lot about blast resistant windows, our sales rep, Gary
          made the process so simple. His knowledge and quick response time made
          our experience flawless.
        </p>
      </li>
      <li class="author">Jennifer Goggans, Goggans, LLC</li>
    </ul>
  );
}
